<template>
  <v-card class="mx-auto" elevation="7">
    <v-card-title> {{ page.name }} </v-card-title>

    <v-card-subtitle>
      <div class="text-left" v-for="domain in page.domains" :key="domain">
        {{ domain }}
      </div>
    </v-card-subtitle>

    <v-card-text class="text-left">
      <div class="text--primary">
        {{ page.source.type }} repo: {{ page.source.config.repo_name }}.
      </div>
      <div class="text--primary">
        Latest Deploy date time:
        {{
          page.latest_deployment.modified_on
            | moment("HH:mm:ss dddd, Do MMMM YYYY")
        }}
      </div>
      <div class="text--primary">
        Latest Deploy URL:
        <a :href="page.latest_deployment.url" target="_blank">{{
          page.latest_deployment.url
        }}</a>
      </div>
      <div class="text--primary toCapitalFirst">
        Latest Deploy type:
        {{ page.latest_deployment.environment }}
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn color="blue lighten-2" text @click="visit(page.domains)">
        Visit website
      </v-btn>

      <v-btn
        color="blue lighten-2"
        text
        @click="visit_repo(page.source.config)"
      >
        Visit repo
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn text @click="show = !show">
        Env vars
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <env-vars v-bind="{ page }" />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import EnvVars from "./EnvVars.vue";
export default {
  components: { EnvVars },
  name: "PageCard",
  props: {
    page: {
      type: Object,
    },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    visit(urls) {
      let domain = urls[urls.length - 1];
      let link = "https://" + domain;
      window.open(link, "_blank").focus();
    },
    visit_repo(git_config) {
      let link =
        "https://github.com/" + git_config.owner + "/" + git_config.repo_name;
      window.open(link, "_blank").focus();
    },
  },
};
</script>

<style>
.toCapitalFirst {
  text-transform: capitalize;
}
</style>
