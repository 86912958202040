<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left">Var</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(value, name) in page.deployment_configs.production.env_vars"
          :key="name"
        >
          <td class="text-left">{{ name }}</td>
          <td class="text-left">{{ value.value }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "EnvVars",
  props: {
    page: {
      type: Object,
    },
  },
  data: () => ({
    show: false,
  }),
};
</script>

<style></style>
