<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/cf.svg')"
          class="my-3"
          contain
          height="130"
        />
      </v-col>

      <v-col cols="12" v-if="loading">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>

      <v-col v-if="!loading">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="search"
                label="Search Websites"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="page in filteredPages" :key="page.id">
            <v-col>
              <page-card v-bind="{ page }" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CFService from "@/services/CFBackEndService.js";
import PageCard from "../components/PageCard";

export default {
  name: "CFPages",

  created() {
    CFService.pages("96ab844e2e9d8d96f4490f3d204e51db")
      .then(({ data }) => {
        this.cf_pages = data.result;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

  computed: {
    filteredPages() {
      return this.cf_pages.filter((page) => {
        return page.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
  },

  data: () => ({
    cf_pages: [],
    search: "",
    loading: true,
  }),

  components: {
    PageCard,
  },
};
</script>
