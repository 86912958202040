import axios from "axios";

const api_url = process.env.VUE_APP_CF_API_URL;

const apiClient = axios.create({
  baseURL: api_url,
  // withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default {
  pages(id) {
    return apiClient.get("/api/pages/" + id, {
      headers: { Authorization: axios.defaults.headers.common.Authorization },
    });
  },
};
