<template>
  <CFPages />
</template>

<script>
import CFPages from "../components/CFPages";

export default {
  name: "Home",

  components: {
    CFPages,
  },
};
</script>
